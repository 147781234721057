import { captureException } from '@sentry/react';
import type { ErrorInfo, ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { SENTRY_ENABLED } from '@/environment';

import Fallback from './components/Fallback';

export type ErrorBoundaryProps = {
  children: ReactNode;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const logError = (error: Error, info: ErrorInfo) => {
    if (SENTRY_ENABLED) {
      captureException({ info, error });
    }
  };

  return (
    <ReactErrorBoundary FallbackComponent={Fallback} onError={logError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
