import { captureException } from '@sentry/react';
import type { ErrorInfo } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import SimpleBar from 'simplebar-react';

import { SENTRY_ENABLED } from '@/environment';
import { useAuth } from '@/hooks';

import mapAccesses from '../utils/mapAccesses';
import Section from './Section';

const NavList = () => {
  const { user } = useAuth();
  const accesses = user?.accesses;
  const sections = mapAccesses(accesses);

  const logError = (error: Error, info: ErrorInfo) => {
    if (SENTRY_ENABLED) {
      captureException({ info, error });
    }

    console.error(error, info);
  };

  const FallbackComponent = () => <div />;

  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={logError}
    >
      <SimpleBar className="pb-6" style={{ maxHeight: 'calc(100vh - 55px)' }}>
        <nav>
          <ul className="flex flex-col">
            {sections.map(({ items, key }) => (
              <Section items={items} key={key} sKey={key} />
            ))}
          </ul>
        </nav>
      </SimpleBar>
    </ReactErrorBoundary>
  );
};

export default NavList;
