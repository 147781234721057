export const any = (xs?: object) => (xs ? Object.keys(xs).length > 0 : false);
export const isObject = (value: any) =>
  typeof value === 'object' && value !== null && !Array.isArray(value);
export const clean = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj
      .map(clean)
      .filter(
        item =>
          item !== null && item !== undefined && Object.keys(item).length > 0
      );
  } else if (obj && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc: any, [key, value]) => {
      const cleanedValue = clean(value);

      if (
        cleanedValue !== null &&
        cleanedValue !== undefined &&
        (typeof cleanedValue !== 'object' ||
          Object.keys(cleanedValue).length > 0)
      ) {
        acc[key] = cleanedValue;
      }

      return acc;
    }, {});
  }

  return obj;
};
