import { useFeatureFlags } from '@/hooks';
import { t } from '@/translations/i18n';

import type {
  Item as ItemType,
  Supercategory as SupercategoryType
} from '../types';
import Item from './Item';
import Supercategory from './Supercategory';

export type SectionProps = {
  items: (SupercategoryType | ItemType)[];
  sKey: string;
};

const Section = ({ items, sKey: key }: SectionProps) => {
  const { separatedReportsDash } = useFeatureFlags();

  return (
    <li>
      <p className="translate-x-1.5 translate-y-1.5 px-6 py-4 text-2xs font-bold uppercase tracking-wider text-tertiary">
        {t(`nav.${key}`)}
      </p>

      {items.map(item => {
        if (
          separatedReportsDash &&
          item.key === 'reports' &&
          item.type === 'item'
        ) {
          return null;
        } else if (
          !separatedReportsDash &&
          item.key === 'reports' &&
          item.type === 'supercategory'
        ) {
          return null;
        }

        return item.type === 'supercategory' ? (
          <Supercategory item={item as SupercategoryType} key={item.key} />
        ) : (
          <Item item={item as ItemType} key={item.key} />
        );
      })}
    </li>
  );
};

export default Section;
