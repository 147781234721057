import { useLocation, useMatch } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import Link from '@/components/Link';
import { useFeatureFlags } from '@/hooks';
import { t } from '@/translations/i18n';

import { Item as ItemType } from '../types';
import itemEnabled from '../utils/itemEnabled';

type ItemProps = {
  item: ItemType;
  subitem?: boolean;
};

const Item = ({ item, subitem = false }: ItemProps) => {
  const { key, path, subpaths } = item;

  const features = useFeatureFlags();
  const { pathname } = useLocation();

  const pathMatches = useMatch({ path, end: false });
  const subpathMatches = subpaths?.some(subpath => pathname.includes(subpath));
  const highlightLink = subpathMatches || pathMatches;

  return itemEnabled(key, features) ? (
    <Link
      className={twMerge(
        'flex h-[43.53px] -translate-x-1 whitespace-nowrap py-2 pl-12 pr-6 pt-[9px] text-[15px] text-tertiary transition-colors duration-300 ease-out hover:text-linkHover',
        highlightLink && 'text-white',
        subitem && 'h-[37.36px] -translate-x-0 pl-14 pt-2 text-sm'
      )}
      redirectTo={path}
    >
      {t(`nav.${key}`)}
    </Link>
  ) : null;
};

export default Item;
