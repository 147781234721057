import { FaCog } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';

import Link from '@/components/Link';
import server from '@/services/server';
import { redirectToSignIn } from '@/utils/redirectToSignIn';

import type { AccountOptionDropdownProps } from '../header.types';
import DeveloperOptions from './DeveloperOptions';

const AccountOptionDropdown = ({ isOpen }: AccountOptionDropdownProps) => {
  const logout = async () => {
    await server.get({ path: 'users/sign_out' });
    redirectToSignIn();
  };

  return (
    <div
      className={twMerge(
        'pointer-events-none absolute right-0 top-18 flex w-49 -translate-y-4 transform flex-col gap-2 bg-white pb-2 pt-4 opacity-0 shadow-sm transition-all',
        isOpen && 'pointer-events-auto z-50 translate-y-0 opacity-100'
      )}
    >
      <p className="px-4 text-sm font-semibold text-secondary">Welcome!</p>
      <div className="flex flex-col gap-0.5">
        <DeveloperOptions />
        <Link
          className="flex items-center gap-2 px-4 py-2 hover:bg-background"
          redirectTo="/users/edit"
        >
          <FaCog />
          <span className="text-sm font-semibold text-secondary">
            Change password
          </span>
        </Link>
        <button
          className="flex items-center gap-2 px-4 py-2 hover:bg-background"
          onClick={logout}
        >
          <TbLogout />
          <span className="text-sm font-semibold text-secondary">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default AccountOptionDropdown;
