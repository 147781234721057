import NavHeader from './subcomponents/NavHeader';
import NavList from './subcomponents/NavList';

const Nav = () => (
  <div className="fixed z-10 flex h-full w-66 flex-col gap-2 bg-primary lg:z-50">
    <NavHeader />
    <NavList />
  </div>
);

export default Nav;
