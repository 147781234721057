type AnyObject = Record<string, unknown>;

const convertKeys = (
  obj: unknown,
  converter: (str: string) => string
): unknown =>
  typeof obj === 'object' && obj !== null
    ? Array.isArray(obj)
      ? obj.map(item => convertKeys(item, converter))
      : Object.keys(obj as AnyObject).reduce((acc: AnyObject, key) => {
          acc[converter(key)] = convertKeys((obj as AnyObject)[key], converter);
          return acc;
        }, {})
    : obj;

export const snakeToCamelCase = (obj: unknown): unknown => {
  const converter = (str: string) =>
    str.replace(/[-_][a-z0-9]/gi, $1 => $1[1].toUpperCase());

  return typeof obj === 'string' ? converter(obj) : convertKeys(obj, converter);
};

export const camelToSnakeCase = (obj: unknown): unknown => {
  const converter = (str: string) =>
    str.replace(/[A-Z0-9]/g, letter => `_${letter.toLowerCase()}`);

  return typeof obj === 'string' ? converter(obj) : convertKeys(obj, converter);
};

export const snakeCaseToNormalCase = (str?: string): string => {
  if (!str) return '';

  const words = str.split('_');
  const convertedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return convertedWords.join(' ');
};

export const snakeCase = (str: string): string => {
  const words = str
    .replace(/([A-Z])|(?<=[a-zA-Z])(\d+)/g, ' $1$2')
    .trim()
    .split(/[\s\-_]+/);

  const convertedWords = words.map(word => word.toLowerCase());

  return convertedWords.join('_');
};
