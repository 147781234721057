import type { TransformedFeatureFlags } from '@/hooks/useFeatureFlags';

const itemEnabled = (key: string, features: TransformedFeatureFlags) => {
  const {
    averageOrderStatsReportDash,
    commissionReportDash,
    freightHandlingChargebackReportDash,
    royaltyReportDash,
    specialComponentsDash
  } = features;

  switch (key) {
    case 'special_components':
      return specialComponentsDash ?? false;
    case 'average_order_stats_report':
      return averageOrderStatsReportDash ?? false;
    case 'commission_report':
      return commissionReportDash ?? false;
    case 'freight_handling_chargeback_report':
      return freightHandlingChargebackReportDash ?? false;
    case 'royalty_report':
      return royaltyReportDash ?? false;
    case undefined:
    case null:
      return false;
    default:
      return true;
  }
};

export default itemEnabled;
