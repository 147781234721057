import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Backdrop from '@/components/Backdrop';
import Button from '@/components/Button';
import Link from '@/components/Link';
import Svg from '@/components/Svg';
import { useAuth } from '@/hooks';

import useStopImpersonate from '../hooks/useStopImpersonating';
import AccountOptionDropdown from './AccountOptionDropdown';

const AccountOption = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const { stopImpersonate } = useStopImpersonate();

  const toggleIsOpen = () => {
    setIsOpen(isOpen => !isOpen);
  };

  return (
    <div className="flex h-full items-center gap-6">
      {user?.impersonates ? (
        <Button className="whitespace-nowrap" danger onClick={stopImpersonate}>
          Back to Own Account
        </Button>
      ) : (
        <Link className="flex items-center" redirectTo="/admin">
          <Svg icon="cogwheel" stroke="#98A6AD" />
        </Link>
      )}
      <button
        className={twMerge(
          'flex h-full w-full items-center gap-2.5 border-x border-[#F1F3FA] bg-[#FAFBFD] pl-4 pr-5 text-tertiary',
          isOpen && 'z-50'
        )}
        onClick={toggleIsOpen}
      >
        <div className="flex w-8">
          <Svg icon="avatarPlaceholder" stroke="#98A6AD" />
        </div>
        <div className="hidden flex-col text-left md:flex">
          <p
            className={`text-[14.4px] font-semibold leading-4 ${
              isOpen ? 'text-secondary' : 'text-quaternary'
            } `}
          >
            {user?.email ?? ''}
          </p>
          <p
            className={twMerge(
              'text-xs leading-4 text-quaternary',
              isOpen && 'text-secondary'
            )}
          >
            {user?.name ?? ''}
          </p>
        </div>
      </button>
      <AccountOptionDropdown isOpen={isOpen} />
      {isOpen && <Backdrop onClick={toggleIsOpen} />}
    </div>
  );
};

export default AccountOption;
