import useSWR from 'swr';

import server from '@/services/server';
import type { User } from '@/types/User';

const useAuth = () => {
  const getUser = async (): Promise<User> => {
    return await server.get({ path: 'user' });
  };

  const { data, error, isLoading, mutate } = useSWR('auth', getUser);

  return {
    error,
    isLoading,
    mutate,
    user: data?.data
  };
};

export default useAuth;
