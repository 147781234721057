import CompanySelect from '@/components/CompanySelect';

import type { HeaderProps } from './header.types';
import AccountOption from './subcomponents/AccountOption';
import MenuButton from './subcomponents/MenuButton';

const Header = ({ toggleNav }: HeaderProps) => (
  <div className="sticky top-0 z-40 flex h-17.5 items-center justify-between bg-white px-6 shadow-[0_0_35px_0_rgba(154,161,171,0.15)]">
    <div className="flex h-full items-center justify-center pt-1">
      <div className="flex h-full w-15 justify-center">
        <MenuButton toggleMenu={toggleNav} />
      </div>
      <CompanySelect />
    </div>
    <AccountOption />
  </div>
);

export default Header;
