import type { Access } from '@/types/Access';
import { clean } from '@/utils/object';

import type { Section } from '../types';

const mapAccesses = (accesses: Access[] = []): Section[] => {
  const sections = accesses?.reduce((acc: any[], access) => {
    const { key, position } = access.section;
    const supercategoryKey = access.supercategory.key;

    if (!acc[position]) {
      acc[position] = { key: key, items: [] };
    }

    if (supercategoryKey) {
      let supercategory = acc[position].items.find(
        (item: any) =>
          item?.key === supercategoryKey && item?.type === 'supercategory'
      );

      if (!supercategory) {
        supercategory = {
          key: supercategoryKey,
          type: 'supercategory',
          items: []
        };

        acc[position].items[access.position] = supercategory;
      }

      supercategory.items[access.position] = {
        key: access.key,
        path: access.path,
        subpaths: access.subpaths,
        type: 'item'
      };
    } else {
      acc[position].items[access.position] = {
        key: access.key,
        path: access.path,
        subpaths: access.subpaths,
        type: 'item'
      };
    }

    return acc;
  }, []);

  return clean(sections);
};

export default mapAccesses;
