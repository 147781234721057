const routes = {
  adsCreator: '/next/ads_containers',
  backgroundImages: '/next/style_builder/images_library',
  budgets: '/next/budgets',
  cartRules: '/next/cart_rules',
  companies: '/next/companies',
  companyGroupCostCodes: '/next/drop_down_codes',
  customDomains: '/next/custom_domains',
  customerSupport: '/next/order_statuses',
  dealerLogos: '/next/dealer_logos',
  featuredProducts: '/next/style_builder/featured_containers',
  fixedShippingLocations: '/next/fixed_shipping_locations',
  giftCertificates: '/next/gift_certificates',
  logoManagement: '/next/logos',
  mainColors: '/next/main_colors',
  massLoadParts: '/next/mass_load_parts',
  masterParts: '/next/master_parts',
  nonMasterPartGroups: '/next/non_master_part_groups',
  passwordUsers: '/next/password_users',
  pickToShip: '/next/pick_to_ship',
  primaryInformation: '/next/style_builder/brand_settings',
  productColors: '/next/product_colors',
  reports: '/next/reports',
  sliderCreator: '/next/sliders',
  specialComponents: '/next/special_components',
  storeAgreements: '/next/store_agreements',
  validCodes: '/next/valid_codes',
  vendorInventories: '/next/vendor_inventories',
  workflowProduction: '/next/workflow_production_pages'
};

export default routes;
