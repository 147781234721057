import { Suspense, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';

import Header from '@/components/Header';
import Nav from '@/components/Nav';
import ErrorBoundary from '@/pages/ErrorBoundary';
import { RequireAuth } from '@/providers';

import DeveloperMode from './DeveloperMode';

const PageContainer = () => {
  const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
  const [navOpen, setNavOpen] = useState(isLg);

  const toggleNav = () => setNavOpen(open => !open);

  useEffect(() => setNavOpen(isLg), [isLg]);

  return (
    <RequireAuth>
      <div className="flex h-full min-h-screen bg-siteBackground">
        {navOpen && <Nav />}
        {navOpen && !isLg && (
          <div className="fixed inset-0 z-0" onClick={toggleNav} />
        )}
        <div className={`w-full ${navOpen ? 'lg:pl-66' : ''}`}>
          <Header toggleNav={toggleNav} />
          <DeveloperMode />
          <ErrorBoundary>
            <Suspense>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </RequireAuth>
  );
};

export default PageContainer;
